import { api, createCrudService } from '@cargill/shared'

const service = createCrudService(
  '/api/optimizationConstraintMinimumWithdrawal',
  api
)

service.getValidationContext = async () =>
  (
    await api.get(
      '/api/optimizationConstraintMinimumWithdrawal/validationContext'
    )
  )?.data

export default service
