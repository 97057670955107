<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import service from '../api/optimizationConstraintMinimumWithdrawalService'
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      helpers.updateOptionsRefs(ctx.centerOptions, ctx.centersByCenterType)
      helpers.updateOptionsRefs(ctx.modalOptions, ctx.modalsByModalType)
      const fieldsById = _.keyBy(meta.fields, (f) => f.id)
      fieldsById.minimumWithdrawalUnity.options = ctx.unityOptions
      fieldsById.centerType.options = ctx.centerTypeOptions
      fieldsById.center.options = ctx.centerOptions
      fieldsById.modalType.options = ctx.modalTypeOptions
      fieldsById.modal.options = ctx.modalOptions

      const getCenterOptions = (value) =>
        value?.value == null
          ? ctx.centerOptions
          : ctx.centersByCenterType[value.value]
      fieldsById.centerType.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.center,
          fields.center,
          getCenterOptions(changedValue)
        )
      }

      const getModalOptions = (value) =>
        value?.value == null
          ? ctx.modalOptions
          : ctx.modalsByModalType[value.value]
      fieldsById.modalType.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.modal,
          fields.modal,
          getModalOptions(changedValue)
        )
      }
      return meta
    }

    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
